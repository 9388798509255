import axios from "axios";
import qs from 'qs';

class InternalSlideShowService {
    private _apiHost = 'https://wedding.backend-hamperl.de/api/'
    async getSlideShow() {
        const query = qs.stringify({
            populate: {
                Image: {
                    populate: '*'
                },
            }
        })
        const slideShowResponse = await axios.get(`${this._apiHost}slide-shows?${query}`)
            .then(response => {
                return response.data.data
            })
            .catch(error => {
                console.error('ERROR while loading slider: ', error)
            })

        return slideShowResponse
    }
}

export const SlideShowService = new InternalSlideShowService()