
import {defineComponent, ref, onMounted, PropType} from "vue";
import {Carousel} from "bootstrap";
import {SliderElementItem} from "@/types/SliderElementItem";

export default defineComponent({
  name: 'WeddingSlider',
  props: {
    slides: {
      type: Array as PropType<SliderElementItem[]>,
      required: true
    },
    intervalTime: {
      type: Number as PropType<number>,
      default: 2500,
    }
  },
  setup(props, {emit}) {
    const sliderCarousel = ref<HTMLDivElement | null>(null)
    const bsCarousel = ref<Carousel | null>(null);

    const nextSlide = () => {
      setTimeout(() => {
        if (bsCarousel.value) bsCarousel.value.next()
      }, props.intervalTime)
    }

    onMounted(() => {
      if (sliderCarousel.value) {
        bsCarousel.value = new Carousel(sliderCarousel.value, {
          interval: props.intervalTime,
          wrap: false,
          pause: false,
          ride: 'carousel'
        })

        nextSlide()

        // eslint-disable-next-line
        sliderCarousel.value.addEventListener('slid.bs.carousel', (slid: any) => {
          if (slid.to + 1 === props.slides.length) {
            setTimeout(() => {
              emit('reload')
            }, props.intervalTime)
          }

          nextSlide();
        })
      }

    })

    return {sliderCarousel}
  }
})
