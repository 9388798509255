
import { defineComponent, ref, onMounted } from 'vue';
import WeddingSlider from "@/components/WeddingSlider.vue";
import { SliderElementItem } from "@/types/SliderElementItem";
import {SlideShowService} from "@/services/SlideShowService";

const _apiHost = 'https://wedding.backend-hamperl.de'

export default defineComponent({
  name: 'App',
  components: {
    WeddingSlider
  },
  setup() {
    const loading = ref<boolean>(true)
    const sliderElements = ref<SliderElementItem[]>([])

    const initSlideshow = async () => {
      await SlideShowService.getSlideShow()
      .then(resposne => {
        const newSlides: SliderElementItem[] = [];

        if (resposne && resposne.length > 0) {
          resposne.forEach((slide: { id: number|string; attributes: { Title: string; Image: { data: { attributes: { url: string; }; }; }; }; }) => {
            newSlides.push({
              id: slide.id,
              title: slide.attributes.Title,
              url: _apiHost + slide.attributes.Image.data.attributes.url
            })
          })
        }

        sliderElements.value = newSlides
      })
      .finally(() => {
        loading.value = false
      })
    }

    onMounted(async () => {
      await initSlideshow()
    })

    const reloadSlides = async () => {
      loading.value = true
      await initSlideshow()
    }

    return {loading, sliderElements, reloadSlides}
  }
});
